<template>
  <div>
    <br />
    <br />
    <Row type="flex" justify="center" :gutter="5">
      <Col :xs="20" :sm="18" :md="12" :lg="10">
        <Card>
          <pre style="text-align: center">
          .----.
       _.'__    `.
   .--($)($$)---/#\
 .' @xsdhy     /###\
 :         ,   #####
  `-..__.-' _.-\###/
        `;_:    `"'
      .'"""""`.
     /,  ya ,\\
    //  404!  \\
    `-._______.-'
    ___`. | .'___
   (______|______)
</pre>
          <p style="text-align:center">
            <a href="/">返回主页</a>
          </p>
        </Card>
      </Col>
    </Row>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  created() {
    document.title = "404页面 - 消逝的红叶-我就是我，是不一样的烟火";
  },
  methods: {}
};
</script>